import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { Formik } from 'formik'
import MySelect from './MySelect'

function validationIcons(valid) {
	return valid ? (
		<span className="icon is-small is-right">
			<FontAwesomeIcon icon="exclamation-triangle" />
		</span>
	) : (
		<span className="icon is-small is-right">
			<FontAwesomeIcon icon="check" />
		</span>
	)
}

const windowGlobal = typeof window !== 'undefined' && window
class EnquiryForm extends Component {
	constructor(props) {
		super(props)
		this.client = null
	}

	componentDidMount() {
		if (windowGlobal) {
			const {
				Stitch,
				RemoteMongoClient,
				UserApiKeyCredential,
			} = require('mongodb-stitch-browser-sdk')
			if (!Stitch.hasAppClient('book-a-demo-nnnrq')) {
				this.client = Stitch.initializeDefaultAppClient('book-a-demo-nnnrq')
			} else {
				this.client = Stitch.defaultAppClient
			}
			this.db = this.client
				.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas')
				.db('bookings')
		}
	}

	render() {
		const {
			modalState,
			formName,
			openForm,
			shopName,
			shopEmail,
			shopAddress,
			storeCountry,
			modelOptions,
			regionOptions,
			conditionOptions,
			relativeUrl,
		} = this.props
		return (
			<div
				className={modalState ? `is-active modal` : `modal`}
				id={`form-is-${formName}`}
			>
				<div className="modal-background" onClick={() => openForm('')} />
				<div className="modal-content">
					<div className="box">
						<div className="content">
							<h1 className="title">
								<span className="icon">
									<FontAwesomeIcon icon="comment" />
								</span>{' '}
								{formName === 'free-demo' ? 'Free Demo' : ''}
								{formName === 'more-info' ? 'More Info' : ''}
							</h1>
							<h2 className="subtitle">
								{formName === 'free-demo'
									? 'Book your Free robotic pool cleaning Demo'
									: ''}
								{formName === 'more-info'
									? 'Contact us to get more information'
									: ''}
							</h2>
							<Formik
								initialValues={{
									name: '',
									email: '',
									mobile: '',
									address: '',
									model: '',
									pool_condition: '',
									pool_size: '',
									pool_surface: '',
								}}
								validate={values => {
									const errors = {}
									// * Full Name *REQUIRED
									if (!values.name) {
										errors.name = 'Please provide your full name.'
									}
									// * Email *REQUIRED
									if (!values.email) {
										errors.email = 'Please provide an email address.'
									} else if (
										!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(
											values.email
										)
									) {
										errors.email = 'Invalid email address.'
									}
									// * Mobile *REQUIRED
									if (!values.phone) {
										errors.phone = 'Please provide a phone number.'
										// } else if (
										// 	!/^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/.test(
										// 		values.phone
										// 	)
										// ) {
										// 	errors.phone = 'Invalid Australian number.'
									}
									// * Address *REQUIRED
									if (!values.address) {
										errors.address = 'Please provide your address.'
									}
									// * Model *REQUIRED
									// if (!values.model) {
									// 	errors.model = 'Please provide your preferred model(s).'
									// }
									// * Pool Condition *REQUIRED
									if (!values.pool_condition) {
										errors.pool_condition =
											'Please provide your pools condition.'
									}
									// * Pool Size *REQUIRED
									// if (!values.pool_size) {
									// 	errors.pool_size = 'Please provide your pools size.'
									// }
									// * Pool Surface *REQUIRED
									// if (!values.pool_surface) {
									// 	errors.pool_surface = 'Please provide your pools surface.'
									// }
									return errors
								}}
								onSubmit={(
									values,
									{ setSubmitting, setErrors, setStatus, resetForm, setTouched }
								) => {
									setSubmitting(true)
									const robots = values.model.map(model => model.value)
									this.client.auth
										.loginWithCredential(
											new UserApiKeyCredential(process.env.GATSBY_MONGODB)
										)
										.then(() => {
											this.client
												.callFunction('miniLead', [
													{
														formName: formName,
														name: values.name,
														email: values.email,
														phone: values.phone,
														address: values.address,
														model: robots.toString(),
														pool_condition: values.pool_condition.value.toString(),
														pool_size: values.pool_size,
														pool_surface: values.pool_surface,
														shop_name: shopName,
														shop_email: shopEmail,
														shop_address: shopAddress,
														branchEmail: 'customercareau@maytronics.com',
													},
												])
												.then(() => {
													setSubmitting(false)
													resetForm()
													navigate(`/${relativeUrl}/?form=${formName}`)
													setStatus('success')
												})
												.catch(err => {
													console.error(
														`MongoDB Stitch fn() Error:\n`,
														err.message
													)
													setSubmitting(false)
													setStatus('failure')
												})
										})
										.catch(err => {
											console.error(`MongoDB API Auth is bad:\n`, err.message)
											setSubmitting(false)
											setStatus('failure')
										})
								}}
								render={({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setStatus,
									status,
									setFieldValue,
									setFieldTouched,
								}) => (
									<form
										name="free-demo"
										method="post"
										data-netlify="true"
										data-netlify-honeypot="bot-field"
										onSubmit={handleSubmit}
										className="form"
									>
										<input
											type="hidden"
											name="form-name"
											defaultValue="free-demo"
										/>
										<input
											type="text"
											name="shop_name"
											defaultValue={shopName}
											hidden
										/>
										<input
											type="email"
											name="shop_email"
											defaultValue={shopEmail}
											hidden
										/>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label" htmlFor="name">
													Full Name
												</label>
											</div>
											<div className="field-body">
												<div
													className="field is-expanded"
													aria-describedby="name"
												>
													<div className="control has-icons-left has-icons-right">
														<input
															id="name"
															name="name"
															type="text"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.name}
															placeholder="John Smith"
															className={classNames(
																touched.name && errors.name && `is-danger`,
																values.name && !errors.name && `is-success`,
																`input`
															)}
														/>
														<span className="icon is-small is-left">
															<FontAwesomeIcon icon="user" />
														</span>
														{touched.name &&
															errors.name &&
															validationIcons(true)}
														{values.name &&
															!errors.name &&
															validationIcons(false)}
													</div>
													{touched.name && errors.name && (
														<p className="help is-danger" id="name-helper-text">
															{errors.name}
														</p>
													)}
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label" htmlFor="email">
													Email
												</label>
											</div>
											<div className="field-body">
												<div
													className="field is-expanded"
													aria-describedby="email"
												>
													<div className="control has-icons-left has-icons-right">
														<input
															id="email"
															name="email"
															type="email"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.email}
															placeholder="you@example.com"
															className={classNames(
																touched.email && errors.email && `is-danger`,
																values.email && !errors.email && `is-success`,
																`input`
															)}
														/>
														<span className="icon is-small is-left">
															<FontAwesomeIcon icon="envelope" />
														</span>
														{touched.email &&
															errors.email &&
															validationIcons(true)}
														{values.email &&
															!errors.email &&
															validationIcons(false)}
													</div>
													{touched.email && errors.email && (
														<p
															className="help is-danger"
															id="email-helper-text"
														>
															{errors.email}
														</p>
													)}
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label" htmlFor="phone">
													Phone
												</label>
											</div>
											<div className="field-body">
												<div
													className="field is-expanded"
													aria-describedby="phone"
												>
													<div className="control has-icons-left has-icons-right">
														<input
															id="phone"
															name="phone"
															type="tel"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.phone}
															placeholder="Phone"
															className={classNames(
																touched.phone && errors.phone && `is-danger`,
																values.phone && !errors.phone && `is-success`,
																`input`
															)}
														/>
														<span className="icon is-small is-left">
															<FontAwesomeIcon icon="phone" />
														</span>
														{touched.phone &&
															errors.phone &&
															validationIcons(true)}
														{values.phone &&
															!errors.phone &&
															validationIcons(false)}
													</div>
													{touched.phone && errors.phone && (
														<p
															className="help is-danger"
															id="phone-helper-text"
														>
															{errors.phone}
														</p>
													)}
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label" htmlFor="address">
													Address
												</label>
											</div>
											<div className="field-body">
												<div
													className="field is-expanded"
													aria-describedby="address"
												>
													<div className="control has-icons-left has-icons-right">
														<input
															id="address"
															name="address"
															type="text"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.address}
															placeholder="Address"
															className={classNames(
																touched.address &&
																	errors.address &&
																	`is-danger`,
																values.address &&
																	!errors.address &&
																	`is-success`,
																`input`
															)}
														/>
														<span className="icon is-small is-left">
															<FontAwesomeIcon icon="user" />
														</span>
														{touched.address &&
															errors.address &&
															validationIcons(true)}
														{values.address &&
															!errors.address &&
															validationIcons(false)}
													</div>
													{touched.address && errors.address && (
														<p
															className="help is-danger"
															id="address-helper-text"
														>
															{errors.address}
														</p>
													)}
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label">Models</label>
											</div>
											<div className="field-body">
												<div className="field" aria-describedby="model-select">
													<div className="control">
														<MySelect
															identity={'model'}
															options={modelOptions}
															value={values.model}
															onChange={setFieldValue}
															onBlur={setFieldTouched}
															errors={errors.model}
															touched={touched.model}
															multi={true}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label">Pool Condition</label>
											</div>
											<div className="field-body">
												<div
													className="field"
													aria-describedby="pool_condition-select"
												>
													<div className="control">
														<MySelect
															identity={'pool_condition'}
															options={conditionOptions}
															value={values.pool_condition}
															onChange={setFieldValue}
															onBlur={setFieldTouched}
															errors={errors.pool_condition}
															touched={touched.pool_condition}
															multi={false}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label" htmlFor="pool-size">
													Pool Size
												</label>
											</div>
											<div className="field-body">
												<div
													className="field is-expanded"
													aria-describedby="pool-size"
												>
													<div className="control has-icons-left has-icons-right">
														<input
															id="pool-size"
															name="pool_size"
															type="text"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.pool_size}
															placeholder="25000L"
															className={classNames(
																touched.pool_size &&
																	errors.pool_size &&
																	`is-danger`,
																values.pool_size &&
																	!errors.pool_size &&
																	`is-success`,
																`input`
															)}
														/>
														<span className="icon is-small is-left">
															<FontAwesomeIcon icon="cube" />
														</span>
														{touched.pool_size &&
															errors.pool_size &&
															validationIcons(true)}
														{values.pool_size &&
															!errors.pool_size &&
															validationIcons(false)}
													</div>
													{touched.pool_size && errors.pool_size && (
														<p
															className="help is-danger"
															id="pool-size-helper-text"
														>
															{errors.pool_size}
														</p>
													)}
												</div>
											</div>
										</div>
										<div className="field is-horizontal">
											<div className="field-label">
												<label className="label" htmlFor="pool-surface">
													Pool Surface
												</label>
											</div>
											<div className="field-body">
												<div
													className="field is-expanded"
													aria-describedby="pool-surface"
												>
													<div className="control has-icons-left has-icons-right">
														<input
															id="pool-surface"
															name="pool_surface"
															type="text"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.pool_surface}
															placeholder="Tiles, Pebbles, Concrete"
															className={classNames(
																touched.pool_surface &&
																	errors.pool_surface &&
																	`is-danger`,
																values.pool_surface &&
																	!errors.pool_surface &&
																	`is-success`,
																`input`
															)}
														/>
														<span className="icon is-small is-left">
															<FontAwesomeIcon icon="swimming-pool" />
														</span>
														{touched.pool_surface &&
															errors.pool_surface &&
															validationIcons(true)}
														{values.pool_surface &&
															!errors.pool_surface &&
															validationIcons(false)}
													</div>
													{touched.pool_surface && errors.pool_surface && (
														<p
															className="help is-danger"
															id="pool-surface-helper-text"
														>
															{errors.pool_surface}
														</p>
													)}
												</div>
											</div>
										</div>
										<p className="has-text-centered">
											<button
												type="submit"
												disabled={isSubmitting}
												className={classNames(
													isSubmitting && `is-loading`,
													`button is-secondary is-medium`
												)}
											>
												Submit
											</button>
										</p>
										<div
											className={
												status === 'success' ? `is-active modal` : `modal`
											}
											id="form-success"
										>
											<div
												className="modal-background"
												onClick={() => {
													setStatus(''), openForm('')
												}}
											>
												<div className="before" />
												<div className="after" />
											</div>
											<div className="modal-content">
												<div className="box">
													<div className="content">
														<h1>We have recieved your enquiry 😄</h1>
														<p>
															Thanks for taking the time to contact us.
															<br />
															We will be in touch soon.
														</p>
													</div>
												</div>
											</div>
											<button
												className="modal-close is-large"
												aria-label="close"
												onClick={() => setStatus('')}
											/>
										</div>
										<div
											className={
												status === 'failure' ? `is-active modal` : `modal`
											}
											id="form-failure"
										>
											<div
												className="modal-background"
												onClick={() => setStatus('')}
											/>
											<div className="modal-content">
												<div className="box">
													<div className="content">
														<h1>Something went wrong 😞</h1>
														<p>Please check your details and try again.</p>
													</div>
												</div>
											</div>
											<button
												className="modal-close is-large"
												aria-label="close"
												onClick={() => setStatus('')}
											/>
										</div>
									</form>
								)}
							/>
						</div>
					</div>
				</div>
				<button
					className="modal-close is-large"
					aria-label="close"
					onClick={() => openForm('')}
				/>
			</div>
		)
	}
}

EnquiryForm.propTypes = {
	modalState: PropTypes.bool,
	formName: PropTypes.string,
	openForm: PropTypes.func,
	shopName: PropTypes.string,
	shopEmail: PropTypes.string,
	storeCountry: PropTypes.string,
	modelOptions: PropTypes.array,
	regionOptions: PropTypes.array,
	conditionOptions: PropTypes.array,
	relativeUrl: PropTypes.string,
}

export default EnquiryForm
