import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DolphinBrand from '../../img/dolphin.svg'
import M500 from '../../img/M500.png'
import S300i from '../../img/S300i.png'
import E30Plus from '../../img/E30Plus.png'

const ProductStockist = props => {
	const { prettyStoreUrl, storeCountry } = props
	return (
		<div className="ProductStockist">
			<div className="stockist">
				<div className="banner">
					<div className="robot">
						<figure className="image is-128x128">
							<img
								className="is-rounded"
								src={M500}
								alt="Stockist of the Dolphin M Series"
								title="Stockist of the Dolphin M Series"
							/>
						</figure>
					</div>
					<div className="details">
						<h1>Stockist of the</h1>
						<div className="branding">
							<div className="dolphin">
								<img
									src={DolphinBrand}
									alt="It's A Dolphin"
									title="It's A Dolphin"
								/>
							</div>
							<div className="series">
								<div className="tags has-addons">
									<span className="tag is-white letter">M</span>
									<span className="tag is-primary series">Series</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="links">
					<div className="link">
						<a href="https://youtu.be/2NM8Vk9do-k">
							<span className="icon">
								<FontAwesomeIcon
									className="has-text-danger"
									icon={['fab', 'youtube']}
								/>
							</span>
							&nbsp;
							<strong>M Series</strong> Dolphin on YouTube
						</a>
					</div>
					{storeCountry === 'ZA' ? (
						''
					) : (
						<div className="link">
							<a href={prettyStoreUrl}>
								<span className="icon">
									<FontAwesomeIcon
										className="has-text-success"
										icon="shopping-bag"
									/>
								</span>
								&nbsp;Buy online at MyDolphin
							</a>
						</div>
					)}
				</div>
			</div>
			<div className="stockist">
				<div className="banner">
					<div className="robot">
						<figure className="image is-128x128">
							<img
								className="is-rounded"
								src={storeCountry === 'ZA' ? E30Plus : S300i}
								alt="Stockist of the Dolphin S Series"
								title="Stockist of the Dolphin S Series"
							/>
						</figure>
					</div>
					<div className="details">
						<h1>Stockist of the</h1>
						<div className="branding">
							<div className="dolphin">
								<img
									src={DolphinBrand}
									alt="It's A Dolphin"
									title="It's A Dolphin"
								/>
							</div>
							<div className="series">
								<div className="tags has-addons">
									<span className="tag is-white letter">
										{storeCountry === 'ZA' ? 'E' : 'S'}
									</span>
									<span className="tag is-primary series">Series</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="links">
					<div className="link">
						<a
							href={
								storeCountry === 'ZA'
									? 'https://youtu.be/vssJ26cEAA4'
									: 'https://youtu.be/xAxOL3cSzfw'
							}
						>
							<span className="icon">
								<FontAwesomeIcon
									className="has-text-danger"
									icon={['fab', 'youtube']}
								/>
							</span>
							&nbsp;
							<strong>{storeCountry === 'ZA' ? 'E' : 'S'} Series</strong>{' '}
							Dolphin on YouTube
						</a>
					</div>
					{storeCountry === 'ZA' ? (
						''
					) : (
						<div className="link">
							<a href={prettyStoreUrl}>
								<span className="icon">
									<FontAwesomeIcon
										className="has-text-success"
										icon="shopping-bag"
									/>
								</span>
								&nbsp;Buy online at MyDolphin
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

ProductStockist.propTypes = {
	prettyStoreUrl: PropTypes.string,
	storeCountry: PropTypes.string,
}

export default ProductStockist
